import { useMutation, useQueryClient } from '@tanstack/react-query'
import { parseEther } from 'viem'


import { approve, deposit } from '~/web3/LendWeb3'
import { toastSuccess } from '~/utils/toast'
import { repay } from '~/web3/borrowWeb3'
import { queries } from '~/consts/queries'

export const useRepayDebt = ({ tokenAddress }) => {

  const queryClient = useQueryClient()

  const repayDebt = async (depositAmount) => {

    try {
      const parsedAmount = parseEther(depositAmount.toString())
      await approve(parsedAmount, tokenAddress)
      await repay(parsedAmount, tokenAddress)

    } catch (error) {
      // Errors are handled in web3 js codes
      throw error
    }
  }

  return useMutation({
    mutationFn: repayDebt,
    onSuccess: () => {

      // Invalidating all caches and whenever the data is used it will be fetched again
      queryClient.invalidateQueries()

      // enforcing refetch becuase unlike other queries token list might have indirect references that won't be updated by invalidating the staleTime of cache
      queryClient.refetchQueries({ queryKey: [queries.GET_TOKENS_LIST] })

      toastSuccess(`Repay Transaction Confirmed!`)
    },
  })

}

export default useRepayDebt