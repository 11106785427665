import { useMutation } from "@tanstack/react-query";

import { toastSuccess } from "~/utils/toast";
import { claimPoints } from "~/web3/core";

export const useClaimPoints = () => {
  return useMutation({
    mutationFn: claimPoints,
    onSuccess: () => {
      // Invalidating all caches and whenever the data is used it will be fetched again
      //   queryClient.invalidateQueries();

      // enforcing refetch becuase unlike other queries token list might have indirect references that won't be updated by invalidating the staleTime of cache
      //   queryClient.refetchQueries({ queryKey: [queries.GET_TOKENS_LIST] });

      toastSuccess(`Refferal Succeed`);
    },
  });
};

export default useClaimPoints;
