import { Table } from 'react-bootstrap'
import useGetDebtPositions from '~/stores/server/liquidation/useGetDebtPositions'
import LiquidationTableRow from './LiquidationTableRow'
import Skeleton from '~/components/Skeleton'
import usePositionLiquidate from '~/stores/server/liquidation/usePositionLiquidate'
import { useState } from 'react'

export default function LiquidationTable() {

    const [buttonLoading, setButtonLoading] = useState(false)

    const { data: positions, isLoading, isPlaceholderData } = useGetDebtPositions()
    const { mutate: liquidate } = usePositionLiquidate()

    const handleLiquidate = (position) => {

        setButtonLoading(true)

        liquidate({ borrowedAssetAddress: position.borrowedAssetAddress, repayAmount: position.usdcDebt, borrowerAddress: position.walletAddress }, {
            onSettled: () => {
                setButtonLoading(false)
            },
        })
    }

    return (
        <div className="radius-8 bg-trans p-4 ">
            <div className='desktop-show'>
                <Table borderless className='gmi_basket_table'>
                    <thead >
                        <tr>
                            <th className='color-gray font-14 bold-300'>#</th>
                            <th className='color-gray font-14 bold-300'>Owner</th>
                            <th className='color-gray font-14 bold-300'>GM Market(s)</th>
                            <th className='color-gray font-14 bold-300'>Total Collateral Value</th>
                            <th className='color-gray font-14 bold-300'>USDC Debt</th>
                            <th className='color-gray font-14 bold-300'>Collateral Ratio</th>
                            <th className='color-gray font-14 bold-300'>Liquidate</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr><td colSpan={7}><hr className='hr-3 border-dark-green m-0' /></td></tr>
                        {
                            positions.length && positions.map((position, index) => (
                                isLoading || isPlaceholderData
                                    ? <tr key={index}><td colSpan={6}>
                                        <Skeleton loading={true}><div></div></Skeleton>
                                    </td></tr>
                                    : <LiquidationTableRow
                                        key={index}
                                        index={index}
                                        position={position}
                                        handleLiquidate={handleLiquidate}
                                        buttonLoading={buttonLoading}
                                    />
                            ))
                        }
                    </tbody>
                </Table>
            </div>
            <div className='mobile-show'>
                {
                    positions.length && positions.map((position, index) => (
                        <Skeleton key={index} loading={isLoading || isPlaceholderData}>
                            <LiquidationTableRow
                                key={index}
                                index={index}
                                position={position}
                                handleLiquidate={handleLiquidate}
                                buttonLoading={buttonLoading}
                                card={true}
                            />
                        </Skeleton>
                    ))
                }
            </div>
        </div>
    )
}