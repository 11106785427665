import { readContract, writeContract, waitForTransactionReceipt } from '@wagmi/core'

import { config } from '~/providers/WalletContextProvider'
import { Web3Exception } from '~/consts/exceptions'
import env from '~/env'

import lendingPoolAbi from '~/consts/abis/LendingPool.json'
import GMInterestRateModel from '~/consts/abis/GMInterestRateModel.json'

// images
import { reopenToastLoading, toastDismiss, toastLoading } from '~/utils/toast'
import useUserStore from '~/stores/client/user'
import { createBigNumber } from '~/utils/math'
import { formatEther, parseEther } from 'viem'

const getTokenTotalBorrow = async (token) => {

  try {
    return await readContract(config, {
      abi: lendingPoolAbi.abi,
      address: env.LENDING_POOL_ADDRESS,
      functionName: 'totalBorrows',
      args: [token.address],
    })
  } catch (error) {
    throw new Web3Exception(`Getting ${token.name} Total Borrows Failed`, { token, error })
  }
}

const getUserBorrowBalance = async (token) => {

  const { walletAddress } = useUserStore.getState()

  try {
    return await readContract(config, {
      abi: lendingPoolAbi.abi,
      address: env.LENDING_POOL_ADDRESS,
      functionName: 'borrowBalance',
      args: [token.address, walletAddress],
    })
  } catch (error) {
    throw new Web3Exception(`Getting User Borrows Balance of ${token.name} Failed`, { token, walletAddress, error })
  }
}

const getUserCollaterals = async () => {

  const { walletAddress } = useUserStore.getState()

  try {
    return await readContract(config, {
      abi: lendingPoolAbi.abi,
      address: env.LENDING_POOL_ADDRESS,
      functionName: 'getCollateral',
      args: [walletAddress],
    })
  } catch (error) {
    throw new Web3Exception(`Getting Total Collateral Failed`, { walletAddress, error })
  }
}

const getCollateralEnabledStatus = async (token) => {
  const { walletAddress } = useUserStore.getState()

  try {
    return await readContract(config, {
      abi: lendingPoolAbi.abi,
      address: env.LENDING_POOL_ADDRESS,
      functionName: 'enabledCollateral',
      args: [walletAddress, token.address],
    })
  } catch (error) {
    throw new Web3Exception(`Getting ${token.name} Collateral Status Failed`, { token, walletAddress, error })
  }
}

const disableCollateral = async (token) => {

  if (!token.collateral)
    return

  let toastId = toastLoading('Please Sign Disable Collateral Transaction')

  try {

    const disableHash = await writeContract(config, {
      abi: lendingPoolAbi.abi,
      address: env.LENDING_POOL_ADDRESS,
      functionName: 'disableAsset',
      args: [token.address],
    })

    toastId = reopenToastLoading(toastId, 'Waiting For Disable Transaction Confirmation...')
    const receipt = await waitForTransactionReceipt(config, { hash: disableHash })
    toastDismiss(toastId)

    return { hash: disableHash, receipt }

  } catch (error) {
    toastDismiss(toastId)
    throw new Web3Exception(`Enabling ${token.name} Collateral Failed`, { token, error })
  }

}

const enableCollateral = async (token) => {

  if (!token.collateral)
    return

  let toastId = toastLoading('Please Sign Enable Collateral Transaction')

  try {

    const enableHash = await writeContract(config, {
      abi: lendingPoolAbi.abi,
      address: env.LENDING_POOL_ADDRESS,
      functionName: 'enableAsset',
      args: [token.address],
    })

    toastId = reopenToastLoading(toastId, 'Waiting For Enable Transaction Confirmation...')
    const receipt = await waitForTransactionReceipt(config, { hash: enableHash })
    toastDismiss(toastId)

    return { hash: enableHash, receipt }

  } catch (error) {
    toastDismiss(toastId)
    throw new Web3Exception(`Enabling ${token.name} Collateral Failed`, { token, error })
  }

}

const calculateInterestRate = (borrowRate) => {
  const oneE18 = createBigNumber(parseEther('1'))

  // Convert borrowRate to BigInt
  const borrowRateBig = createBigNumber(borrowRate)

  // Calculate interest rate
  const numerator = oneE18.add(borrowRateBig)
  const exponent = createBigNumber(126144000)

  // Calculate power using BigInt
  const interestRateBig = numerator.pow(exponent).div(oneE18.pow(exponent.minus(1))).minus(oneE18)

  // Convert interest rate back to a regular number if within safe bounds
  if (interestRateBig <= Number.MAX_SAFE_INTEGER) {
    return Number(interestRateBig)
  } else {
    return interestRateBig // Return BigInt if value is too large
  }
}

const getBorrowTokenAPY = async (token) => {

  try {

    const totalBorrows = parseEther(token.totalBorrows)
    const availableLiquidity = parseEther(token.availableLiquidity)

    const apy = await readContract(config, {
      abi: GMInterestRateModel.abi,
      address: token.gmInterestAddress,
      functionName: 'getCurrentBorrowAPY',
      args: [availableLiquidity, totalBorrows],
    })

    return createBigNumber(formatEther(apy)).mul(100).toFixed(2)
  } catch (error) {
    throw new Web3Exception(`Getting ${token.name} Borrow APY Failed`, { token, error, })
  }
}

const getMaxBorrowableValue = async () => {

  try {
    return await readContract(config, {
      abi: lendingPoolAbi.abi,
      address: env.LENDING_POOL_ADDRESS,
      functionName: 'maxBorrowableValue',
      args: [],
    })
  } catch (error) {
    throw new Web3Exception(`Failed To Get Max BorrowableValue`, { error }, { sendToast: true })
  }
}

const borrow = async (amount, tokenAddress) => {

  let toastId = toastLoading('Please Sign Borrow Transaction')

  try {

    const brrowHash = await writeContract(config, {
      abi: lendingPoolAbi.abi,
      address: env.LENDING_POOL_ADDRESS,
      functionName: 'borrow',
      args: [tokenAddress, amount],
    })

    toastId = reopenToastLoading(toastId, 'Waiting For Borrow Transaction Confirmation...')
    const receipt = await waitForTransactionReceipt(config, { hash: brrowHash })
    toastDismiss(toastId)

    return { hash: brrowHash, receipt }

  } catch (error) {
    toastDismiss(toastId)
    throw new Web3Exception(`Borrow Failed: ${error.shortMessage || 'Uknown Reason!'}`, { amount, tokenAddress, error }, { sendToast: true })
  }
}

const repay = async (amount, tokenAddress) => {

  let toastId = toastLoading('Please Sign Repay Transaction')

  try {

    const repayHash = await writeContract(config, {
      abi: lendingPoolAbi.abi,
      address: env.LENDING_POOL_ADDRESS,
      functionName: 'repay',
      args: [tokenAddress, amount],
    })

    toastId = reopenToastLoading(toastId, 'Waiting For Repay Transaction Confirmation...')
    const receipt = await waitForTransactionReceipt(config, { hash: repayHash })
    toastDismiss(toastId)

    return { hash: repayHash, receipt }

  } catch (error) {
    toastDismiss(toastId)
    throw new Web3Exception(`Repay Failed: ${error.shortMessage || 'Uknown Reason!'}`, { amount, tokenAddress, error }, { sendToast: true })
  }
}


export {
  getTokenTotalBorrow,
  getMaxBorrowableValue,
  getBorrowTokenAPY,
  getUserCollaterals,
  getUserBorrowBalance,
  borrow,
  repay,
  getCollateralEnabledStatus,
  disableCollateral,
  enableCollateral
}