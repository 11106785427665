import PortfolioPositionSingle from './PortfolioPositionSingle'
import PortfolioOverview from './PortfolioOverview'
import usePortfolioStore from '~/stores/client/portfolio'


export default function Portfolio() {
  const selectedPosition = usePortfolioStore((state) => state.selectedPosition)

  return (
    <>
      <div className="px-sm-4 pt-4 pb-4 bg-trans radius-8">
        {
          selectedPosition ? (
            <PortfolioPositionSingle />
          ) : (
            <PortfolioOverview />
          )
        }
      </div>
    </>
  )
}