import { useAccount } from 'wagmi'
import ConnectWalletButton from '~/components/wallet/ConnectWalletButton'
import useUserStore from '~/stores/client/user'
import Skeleton from './Skeleton'

export default function AuthenticatedSection({ className = '', children }) {

  const { isConnected } = useAccount()
  const isAuthenticated = useUserStore((state) => state.isAuthenticated)

  return (
    <>
      {!isConnected ? (
        <div className={`${className} authenticated-section`}>
          <span className='authenticated-section-text'>You Need to Connect Your Wallet To Access This Section</span>
          <ConnectWalletButton />
        </div>
      ) : (
        <Skeleton loading={!isAuthenticated()} height='100%'>
          {children}
        </Skeleton>
      )}
    </>
  )
}