import { useMemo, useState } from 'react'
import { Button } from 'react-bootstrap'
import gloop_img3_url from "../../assets/img/gmi_img3.svg"
import gloop_img9_url from "../../assets/img/gmi_img9.svg"
import market_info from '../../assets/img/market-info.svg'
import link_redirect from '../../assets/img/link-redirect.svg'
import dropdown_img from '../../assets/img/dropdown.svg'
import warning_market from '../../assets/img/warning-market.svg'
import back_img_url from '../../assets/img/back-icon.svg'
import useBorrowStore from '~/stores/client/borrow'
import { routes } from '~/consts/routes'
import { useNavigate } from 'react-router-dom'
import CollateralSwitch from '../collateral/CollateralSwtich'
import Skeleton from '../Skeleton'
import env from '~/env'
import { createBigNumber } from '~/utils/math'
import { truncateAmount } from '~/utils/ui'
import useGetHealthFactor from '~/stores/server/core/useGetHealthFactor'
import useGetConfigurations from '~/stores/server/core/useGetConfigurations'
import useGetMaxBorrowableValue from '~/stores/server/borrow/useGetMaxBorrowableValue'
import useBorrow from '~/stores/server/borrow/useBorrow'
import { ValidationException } from '~/consts/exceptions'
import AsyncButton from '~/components/AsyncButton'
import { PriceInput } from '~/components/PriceInput'
import usePortfolioStore from '~/stores/client/portfolio'
import useGetTokensList from '~/stores/server/core/useGetTokensList'

export default function MarketBorrow() {

  const selectedMarket = useBorrowStore((state) => state.selectedMarket)
  const setSelectedMarket = useBorrowStore((state) => state.setSelectedMarket)
  const setActiveTab = useBorrowStore((state) => state.setActiveTab)
  const setSelectedPosition = usePortfolioStore((state) => state.setSelectedPosition)

  const [informationVisible, setInformationVisible] = useState(true)
  const [amount, setAmount] = useState("")
  const amountPercentages = [25, 50, 75, 100]
  const [selectedAmountPercentage, setSelectedAmountPercentage] = useState(null)
  const [buttonLoading, setButtonLoading] = useState(false)

  const healthFactorQuery = useGetHealthFactor({ token: selectedMarket, amount })
  const currentHealthFactorQuery = useGetHealthFactor({ token: { name: 'USDC', address: env.USDC_TOKEN_ADDRESS }, amount: '0' })
  const configurationsQuery = useGetConfigurations({ token: selectedMarket })
  const maxBorrowAbleQuery = useGetMaxBorrowableValue({})
  const tokenListQuery = useGetTokensList({})

  const { mutate: borrow } = useBorrow({ tokenAddress: env.USDC_TOKEN_ADDRESS })

  const handleMaxClicked = () => {
    setAmount(maxBorrowAble)
  }

  const handleBorrow = () => {

    if (!amount)
      return new ValidationException('Fill Borrow Amount First')

    if (createBigNumber(amount).gt(maxBorrowAble.toString()))
      return new ValidationException('Borrow Amount Is Greater Than Max Borrowable Amount')

    setButtonLoading(true)

    borrow(amount, {
      onSettled: () => {
        setButtonLoading(false)
      },
      onSuccess: () => {
        setAmount('')
      }
    })
  }

  const handleDepositClicked = () => {
    setSelectedPosition(null)
    setActiveTab('portfolio')
  }

  const borrowHealthy = useMemo(() => {
    return createBigNumber(healthFactorQuery?.data || 0).gte(1)
  }, [amount, healthFactorQuery])

  const maxBorrowAble = useMemo(() => {
    return Math.min(maxBorrowAbleQuery?.data) // configurationsQuery?.data?.borrowFactor, 
  }, [maxBorrowAbleQuery]) // configurationsQuery, 

  const buttonDisabledReason = useMemo(() => {

    if (!amount)
      return 'Fill Borrow Amount First'

    if (createBigNumber(amount.toString()).gt(maxBorrowAble.toString()))
      return 'Requested Amount Is Greater Than Max Borrowable Allowed'

    if (healthFactorQuery.isLoading)
      return 'Calculating Health Factor...'

    if (!borrowHealthy)
      return 'Health Factor Is Below 1'

  }, [amount, borrowHealthy, healthFactorQuery, maxBorrowAble])

  const usdcToken = useMemo(() => {

    if (tokenListQuery.isLoading || !tokenListQuery.data)
      return env.EMPTY_VALUE

    return (tokenListQuery.data || []).find(x => x.name === 'USDC')

  }, [tokenListQuery.data])

  const totalDeposit = useMemo(() => {

    if (tokenListQuery.isLoading || !tokenListQuery.data)
      return env.EMPTY_VALUE

    const depositedTokens = (tokenListQuery.data || []).filter(x => x.collateral && x.userPoolBalance !== env.EMPTY_VALUE && x.price !== env.EMPTY_VALUE && createBigNumber(x.userPoolBalance.toString()).gt(0))

    let totalCollertalUSD = createBigNumber(0)

    for (const token of depositedTokens) {
      const depositUSD = createBigNumber(token.userPoolBalance).mul(token.price).toString()
      totalCollertalUSD = totalCollertalUSD.plus(depositUSD)
    }

    return totalCollertalUSD.toString()

  }, [tokenListQuery.data])

  const handlesetSelectedAmountPercentage = (item) => {
    // set the selected health factor
    setSelectedAmountPercentage(item)

    // Hanlde changing amount value based on the selected health factor
    if (maxBorrowAble) {
      setAmount(maxBorrowAble * item / 100)
    }
  }

  return (
    <>
      <div className='d-flex v-center space-between'>
        <div className='w-100 cursur-pointer'>
          <div className='font-18 bold-600 color-white cursor-pointer' onClick={() => setSelectedMarket(null)}>
            <img src={back_img_url} width={14} className='me-2' />
            Back
          </div>
          <hr className="hr-3 border-dark-green" />
        </div>
      </div>
      <div className='market-loop'>
        <div className='mt-2 loop-info'>
          <div className='title'>
            <img src={market_info} width={14} className='me-2' />
            Borrow</div>
          <div className='mt-2'>Borrow up to the LTV of your deposited collateral. See the Transactions Details section to make sure your Health Factor remains above 100%.</div>
        </div>

        <div className='mt-4'>
          {
            maxBorrowAble && createBigNumber(maxBorrowAble.toString()).lte(0)
              ?
              <div className='no-balance-alert'>
                <span>
                  <img src={warning_market} width={20} className='me-3' />
                  Max Borrowable Is 0
                </span>
              </div>
              : null
          }

        </div>

        <div className='mt-4'>
          <div className='loop-input-title'>
            Borrowed

            {
              createBigNumber(amount || 0).gt(0)
                ? <Skeleton loading={healthFactorQuery.isLoading || healthFactorQuery.isFetching || healthFactorQuery?.data === env.EMPTY_VALUE} height='20px' width='100px'>
                  <span title={healthFactorQuery.data}>Health Factor: {truncateAmount(healthFactorQuery.data, 2)}</span>
                </Skeleton>
                : null
            }

          </div>


          <Skeleton loading={!selectedMarket.userPoolBalance || selectedMarket.userPoolBalance === env.EMPTY_VALUE || configurationsQuery.isLoading || configurationsQuery.isFetching}>
            <div style={{ padding: '8px 18px' }} className='radius-8 bg-trans mt-2 desktop-flex space-between v-center'>
              <div className='w-95-100 d-flex v-center my-1 mr-10'>
                <img src={gloop_img3_url} width={29} className='mr-10' /><PriceInput amount={amount} onChange={() => setSelectedAmountPercentage(null)} setAmount={setAmount} /> <span className='font-18 bold-400 color-gray mr-10'>USDC</span>
              </div>
              <div className='w-5-100 d-flex v-center flex-end my-2'>

                <Button className='max-btn market font-12 bold-300 color-gray radius-4 border-gray border-1 px-1 py-1' onClick={handleMaxClicked}>
                  <span>MAX</span>
                </Button>
              </div>
            </div>
          </Skeleton>

          <div className='health-factor-items'>
            {amountPercentages.map((item, index) => (
              <div key={item} className={`health-factor-item ${selectedAmountPercentage === item && 'active'}`} onClick={() => handlesetSelectedAmountPercentage(item)}>{item}% {(index === amountPercentages.length - 1) && '(max)'}</div>
            ))}
          </div>
        </div>

        <div className="mt-4 border-line"></div>
        <div className="information-card mt-4">
          <div className="toggler-bar" onClick={() => setInformationVisible(!informationVisible)}>
            <div>Transaction Details</div>
            <div className='d-flex align-items-center' style={{ gap: '10px' }}>
              <Skeleton loading={selectedMarket.borrowApy === env.EMPTY_VALUE} width='100px'>
                <div className='apy-rate'>{truncateAmount(selectedMarket.borrowApy, 2)}% Borrow APY</div>
              </Skeleton>
              <Skeleton loading={selectedMarket.supplyApy === env.EMPTY_VALUE} width='100px'>
                <div className='apy-rate'>{truncateAmount(selectedMarket.supplyApy, 2)}% Supply APY</div>
              </Skeleton>
              <img src={dropdown_img} width={13} />
            </div>
          </div>
          {informationVisible && (
            <div className='mt-4'>
              <div className='information-detail-title'>Position</div>
              <div className='border-line my-3'></div>
              <div className='information-detail-items'>
                <div className='d-flex justify-content-between'>
                  <span className='detail-title'>Health Factor</span>
                  <Skeleton loading={currentHealthFactorQuery.isLoading} width='80px' height='30px'>
                    <span className='detail-value text-loop-primary'>{truncateAmount(currentHealthFactorQuery.data, 2)}</span>
                  </Skeleton>
                </div>
                <div className='d-flex justify-content-between'>
                  <span className='detail-title'>Deposit</span>
                  <Skeleton loading={totalDeposit === env.EMPTY_VALUE} width='80px' height='30px'>
                    <span className='detail-value'>${truncateAmount(totalDeposit, 2)}</span>
                  </Skeleton>
                </div>
                <div className='d-flex justify-content-between'>
                  <span className='detail-title'>USDC Borrowed</span>
                  <Skeleton loading={!usdcToken || usdcToken.userBorrows === env.EMPTY_VALUE} width='80px' height='30px'>
                    <span className='detail-value'>{truncateAmount(usdcToken.userBorrows, 2)}</span>
                  </Skeleton>
                </div>
                <div className='d-flex justify-content-between'>
                  <span className='detail-title'>Position Value</span>
                  <span className='detail-value'>/</span>
                </div>
                <div className='d-flex justify-content-between'>
                  <span className='detail-title'>Total Debt</span>
                  <Skeleton loading={!usdcToken || usdcToken.userBorrows === env.EMPTY_VALUE} width='80px' height='30px'>
                    <span className='detail-value'>{truncateAmount(usdcToken.userBorrows, 2)} USDC</span>
                  </Skeleton>
                </div>
                <div className='d-flex justify-content-between'>
                  <span className='detail-title'>Collateral</span>
                  <CollateralSwitch className='detail-value' token={selectedMarket} />
                </div>
              </div>
            </div>
          )}
        </div>

        <div className='d-flex aligm-items-center gap-2 mt-4'>
          {/* <AsyncButton onClick={handleDepositClicked} className='bg-trans-0 border-white text-white'>Deposit</AsyncButton> */}
          <AsyncButton onClick={handleBorrow} loading={buttonLoading} disabledreason={buttonDisabledReason}>Borrow</AsyncButton>
        </div>

      </div >
    </>

  )
}