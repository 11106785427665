import { readContract, writeContract, waitForTransactionReceipt } from '@wagmi/core'

import env from '~/env'

import lendingPoolAbi from '~/consts/abis/LendingPool.json'
import erc20Abi from '~/consts/abis/MockERC20.json'
import GMInterestRateModel from '~/consts/abis/GMInterestRateModel.json'
import { Web3Exception } from '~/consts/exceptions'
import { config } from '~/providers/WalletContextProvider'
import { reopenToastLoading, toastDismiss, toastLoading } from '~/utils/toast'
import { getBorrowTokenAPY, getTokenTotalBorrow } from './borrowWeb3'
import { getGloopStakersYieldFactor, getReserveFactorMantissa } from './core'
import { formatEther, parseEther } from 'viem'
import { createBigNumber } from '~/utils/math'
import useUserStore from '~/stores/client/user'

const approve = async (amount, tokenAddress) => {

  let toastId = toastLoading('Please Approve')

  try {

    const approvalHash = await writeContract(config, {
      abi: erc20Abi.abi,
      address: tokenAddress,
      functionName: 'approve',
      args: [env.LENDING_POOL_ADDRESS, amount],
    })

    toastId = reopenToastLoading(toastId, 'Waiting For Approve Transaction Confirmation...')
    const receipt = await waitForTransactionReceipt(config, { hash: approvalHash })
    toastDismiss(toastId)

    return { hash: approvalHash, receipt }

  } catch (error) {
    toastDismiss(toastId)
    throw new Web3Exception(`Approve Failed: ${error.shortMessage || 'Unknown Reason!'}`, { amount, tokenAddress, error }, { sendToast: true })
  }
}

const deposit = async (amount, tokenAddress) => {

  let toastId = toastLoading('Please Sign Deposit Transaction')

  try {

    const depositHash = await writeContract(config, {
      abi: lendingPoolAbi.abi,
      address: env.LENDING_POOL_ADDRESS,
      functionName: 'deposit',
      args: [tokenAddress, amount],
    })

    toastId = reopenToastLoading(toastId, 'Waiting For Deposit Transaction Confirmation...')
    const receipt = await waitForTransactionReceipt(config, { hash: depositHash })
    toastDismiss(toastId)

    return { hash: depositHash, receipt }

  } catch (error) {
    toastDismiss(toastId)
    throw new Web3Exception(`Deposit Failed: ${error.shortMessage || 'Unknown Reason!'}`, { amount, tokenAddress, error }, { sendToast: true })
  }
}

const getSupplyAPY = async (token) => {

  try {
    const reserveFactor = await getReserveFactorMantissa()
    const gloopStakersYieldFactor = await getGloopStakersYieldFactor()
    const borrowApy = await getBorrowTokenAPY(token)

    return createBigNumber(borrowApy).mul(createBigNumber('1e18').minus((gloopStakersYieldFactor).toString()).plus(reserveFactor.toString())).toString()


  } catch (error) {
    throw new Web3Exception(`Getting ${token.name} Supply APY Failed`, { token, error })
  }
}

const withdraw = async (amount, tokenAddress) => {

  let toastId = toastLoading('Please Sign Withdraw Transaction')

  try {

    const withdrawHash = await writeContract(config, {
      abi: lendingPoolAbi.abi,
      address: env.LENDING_POOL_ADDRESS,
      functionName: 'withdraw',
      args: [tokenAddress, amount],
    })

    toastId = reopenToastLoading(toastId, 'Waiting For Withdraw Transaction Confirmation...')
    const receipt = await waitForTransactionReceipt(config, { hash: withdrawHash })
    toastDismiss(toastId)

    return { hash: withdrawHash, receipt }

  } catch (error) {
    toastDismiss(toastId)
    throw new Web3Exception(`Withdraw Failed: ${error.shortMessage || 'Unknown Reason!'}`, { amount, tokenAddress, error }, { sendToast: true })
  }
}


const fetchTotalUnderlying = async (assetAddress) => {
  try {
    const result = await readContract(config, {
      abi: lendingPoolAbi.abi,
      address: env.LENDING_POOL_ADDRESS,
      functionName: "totalUnderlying",
      args: [assetAddress],
    })
    return result
  } catch (error) {
    throw new Web3Exception(`Getting Total Underlying for ${assetAddress} Failed`, {
      assetAddress,
      error,
    })
  }
}

export {
  approve,
  deposit,
  getSupplyAPY,
  withdraw,
  fetchTotalUnderlying
}