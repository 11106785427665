import { queries } from '~/consts/queries'

import { useQuery } from '@tanstack/react-query'
import { useAxios } from '~/plugins/api'
import Endpoint from '~/plugins/endpoint'

const useGetUserDetails = () => {

  const axios = useAxios()

  const getData = async () => {

    const endpoint = Endpoint.getUrl('me')
    const reponse = await axios.get(endpoint)
    return reponse.data.data
  }

  return useQuery({
    queryKey: [queries.GET_USER_DETAILS],
    queryFn: getData,
  })

}

export default useGetUserDetails