import { useQuery } from '@tanstack/react-query'

import { queries } from '~/consts/queries'
import { createBigNumber } from '~/utils/math'
import useGetTokensList from '../core/useGetTokensList'
import env from '~/env'

const useGetTotalCollateralAmount = ({ enabled = true }) => {

  const tokensList = useGetTokensList({})

  const getData = async () => {
    let totalCollateralInUSD = createBigNumber(0)

    const collateralTokens = (tokensList.data || []).filter(x => x.collateral && x.userPoolBalance !== env.EMPTY_VALUE)

    for (const token of collateralTokens) {
      const collateralInUSD = createBigNumber(token.userPoolBalance).mul(token.price)
      totalCollateralInUSD = totalCollateralInUSD.plus(collateralInUSD)
    }

    return totalCollateralInUSD.toString()
  }

  return useQuery({
    queryKey: [queries.GET_TOTAL_COLLATERAL, tokensList],
    queryFn: getData,
    enabled: enabled
  })
}

export default useGetTotalCollateralAmount