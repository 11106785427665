import { useMemo } from 'react'

import { partializeWalletAddress, truncateAmount } from '~/utils/ui'
import { createBigNumber } from '~/utils/math'

import useGetIsUserLiquidable from '~/stores/server/liquidation/useGetIsUserLiquidable'
import useGetHealthFactor from '~/stores/server/core/useGetHealthFactor'

import env from '~/env'

import AsyncButton from '~/components/AsyncButton'
import { LiquidationTotalCollateral } from './LiquidationTotalCollateral'
import Skeleton from '../Skeleton'

//icons
import RoundUSDCIcon from '~/assets/img/tokens/roundUSDC.svg'

export default function LiquidationTableRow({ position, index, handleLiquidate, buttonLoading, card = false }) {

  const isUserLiquidableQuery = useGetIsUserLiquidable({ borrowedAsset: position.borrowedAssetAddress, borrowerAddress: position.walletAddress })
  const healthFactorQuery = useGetHealthFactor({ token: { name: position.borrowedAsset, address: position.borrowedAssetAddress }, amount: position.usdcDebt, account: position.walletAddress })

  const ratio = useMemo(() => {
    return healthFactorQuery.data ? createBigNumber(healthFactorQuery.data).mul(100).toString() : env.EMPTY_VALUE
  }, [healthFactorQuery])

  const ratioColorClass = useMemo(() => {

    const bigRatio = createBigNumber(ratio.toString())

    if (bigRatio.lte(100))
      return 'color-red'

    else if (bigRatio.gt(100) && bigRatio.lte(125))
      return 'color-orange'

    return 'color-green'

  }, [ratio])

  const liquidateButtonDisabled = useMemo(() => {
    return !isUserLiquidableQuery?.data
  }, [isUserLiquidableQuery])

  const sumTotalCollateralValue = useMemo(() => {
    return position.totalCollateralValue.map(x => x.totalValue).reduce((accumulator, currentValue) => {
      return createBigNumber(accumulator).plus(currentValue).toString()
    }, 0)
  }, [position.totalCollateralValue])

  return (
    <>
      {
        card
          ? <div className='d-flex flex-column'>

            <div className='d-flex justify-content-between'>
              <div className='color-gray font-14 bold-300 py-2'>#</div>
              <div className='color-white font-14 bold-600 py-2'>{index + 1}</div>
            </div>

            <div className='d-flex justify-content-between'>
              <div className='color-gray font-14 bold-300 py-2'>Owner</div>
              <div className='color-white font-14 bold-600 py-2'>{partializeWalletAddress(position.walletAddress)}</div>
            </div>

            <div className='d-flex justify-content-between'>
              <div className='color-gray font-14 bold-300 py-2'>GM Market(s)</div>
              <div className='color-white font-14 bold-600 py-2 d-flex justify-content-end align-items-center'><LiquidationTotalCollateral totalCollateralValue={position.totalCollateralValue} /></div>
            </div>

            <div className='d-flex justify-content-between'>
              <div className='color-gray font-14 bold-300 py-2'>Total Collateral Value</div>
              <div className='color-white font-14 bold-600 py-2' title={`$${sumTotalCollateralValue}`}>${truncateAmount(sumTotalCollateralValue, 2)}</div>
            </div>

            <div className='d-flex justify-content-between'>
              <div className='color-gray font-14 bold-300 py-2'>USDC Debt</div>
              <div className='color-white font-14 bold-600 py-2' title={position.usdcDebt}>
                <div className='d-flex justify-content-end align-items-center' style={{ gap: '12px' }}>
                  <img src={RoundUSDCIcon} alt="" />
                  <span className='color-white font-16 bold-700'>{truncateAmount(position.usdcDebt, 2)}</span>
                </div>
              </div>
            </div>

            <div className='d-flex justify-content-between'>
              <div className='color-gray font-14 bold-300 py-2'>Collateral Ratio</div>
              <div className={`${ratioColorClass} font-14 bold-600 py-2`} title={`${ratio}%`}>{truncateAmount(ratio, 2)}%</div>
            </div>

            <div className='d-flex justify-content-between'>
              <div className='color-gray font-14 bold-300 py-2'>Liquidate</div>
              <div className='color-white font-16 bold-600'><AsyncButton disabledreason='User Is Not Liquidatable' loading={isUserLiquidableQuery.isLoading || buttonLoading} className={`${liquidateButtonDisabled ? 'bg-white' : ''} p-6-12`} disabled={liquidateButtonDisabled} onClick={() => handleLiquidate(position)}>Liquidate</AsyncButton></div>
            </div>
            <div>
              <hr className='hr-1 border-dark-green' />
            </div>
          </div>
          : <tr className='py-3'>
            <td className='color-white font-16 bold-700'>{index + 1}</td>
            <td className='color-white font-16 bold-700'>{partializeWalletAddress(position.walletAddress)}</td>
            <td><LiquidationTotalCollateral totalCollateralValue={position.totalCollateralValue} /></td>
            <td className='color-white font-16 bold-700' title={`$${sumTotalCollateralValue}`}>${truncateAmount(sumTotalCollateralValue, 2)}</td>
            <td className='' title={`$${position.usdcDebt}`}>
              <div className='d-flex align-items-center' style={{ gap: '12px' }}>
                <img src={RoundUSDCIcon} alt="" />
                <span className='color-white font-16 bold-700'>{truncateAmount(position.usdcDebt, 2)}</span>
              </div>
            </td>
            <Skeleton loading={healthFactorQuery.isLoading || ratio === env.EMPTY_VALUE}>
              <td className={`${ratioColorClass} font-16 bold-700`} title={`${ratio}%`}>{truncateAmount(ratio, 2)}%</td>
            </Skeleton>
            <td className='color-white font-16 bold-700'><AsyncButton disabledreason='User Is Not Liquidatable' loading={isUserLiquidableQuery.isLoading || buttonLoading} className={`${liquidateButtonDisabled ? 'bg-white' : ''} p-6-12`} disabled={liquidateButtonDisabled} onClick={() => handleLiquidate(position)}>Liquidate</AsyncButton></td>
          </tr>
      }
    </>

  )
}