import { useQuery, useQueryClient } from '@tanstack/react-query'
import { formatEther, formatUnits, parseEther } from 'viem'
import { queries } from '~/consts/queries'

import { getTokensList, getTokenPrice, getTokenAvailableLiquidity, getPoolAssetBalance, getUtilizationRate, getTokenTotalReserves } from '~/web3/core'
import { getBorrowTokenAPY, getTokenTotalBorrow, getUserBorrowBalance, getCollateralEnabledStatus } from '~/web3/borrowWeb3'

import useBorrowStore from '~/stores/client/borrow'
import usePortfolioStore from '~/stores/client/portfolio'
import useGmiStore from '~/stores/client/gmi'
import { getDexBankBalance, getDexTokenPrice, getDexTotalControlledValue, getDexTotalSupply, getTokenVaults, getTransferFee } from '~/web3/GMIWeb3'
import env from '~/env'
import { getSupplyAPY } from '~/web3/LendWeb3'
import useLendStore from '~/stores/client/lend'
import { createBigNumber } from '~/utils/math'

const useGetTokensList = ({ enabled = true }) => {

  const queryClient = useQueryClient()

  const updateQueryCache = (updatedToken) => {
    const tokensList = queryClient.getQueryData([queries.GET_TOKENS_LIST]) || []
    const updatedTokensList = tokensList.map((item) => item.name === updatedToken.name ? updatedToken : item)
    queryClient.setQueryData([queries.GET_TOKENS_LIST], updatedTokensList)
  }

  const tryUpdateBorrowStore = (token) => {

    const { selectedMarket, setSelectedMarket } = useBorrowStore.getState()

    if (selectedMarket && selectedMarket.name === token.name) {
      setSelectedMarket(token)
    }

  }

  const tryUpdatePortflioStore = (token) => {

    const { selectedPosition, setSelectedPosition } = usePortfolioStore.getState()

    if (selectedPosition && selectedPosition.name === token.name) {
      setSelectedPosition(token)
    }

  }

  const tryUpdateGMIStore = (token) => {

    const { selectedToken, setSelectedToken } = useGmiStore.getState()

    if (selectedToken && selectedToken.name === token.name) {
      setSelectedToken(token)
    }

  }

  const tryUpdateLendStore = (token) => {

    const { selectedToken, setSelectedToken } = useLendStore.getState()

    if (selectedToken && selectedToken.name === token.name) {
      setSelectedToken(token)
    }

  }

  const tryUpdateClientStores = (token) => {
    tryUpdateBorrowStore(token)
    tryUpdatePortflioStore(token)
    tryUpdateLendStore(token)
    tryUpdateGMIStore(token)
  }

  const fetchPortfolioFields = async (token) => {
    try {
      const userPoolBalance = await getPoolAssetBalance(token)
      const formattedUserPoolBalance = formatEther(userPoolBalance).toString()
      token.userPoolBalance = formattedUserPoolBalance
    } catch (error) {
    }

    try {
      const userBorrows = await getUserBorrowBalance(token)
      const formattedBorrow = formatEther(userBorrows).toString()
      token.userBorrows = formattedBorrow
    } catch (error) {
    }

    return token
  }

  const fetchBorrowFields = async (token) => {
    try {
      const availableLiquidity = await getTokenAvailableLiquidity(token)
      const formattedAvailableLiquidity = formatUnits(availableLiquidity, token?.decimals).toString()
      token.availableLiquidity = formattedAvailableLiquidity
    } catch (error) {
    }

    try {
      const totalReserves = await getTokenTotalReserves(token)
      token.totalReserves = totalReserves.toString()
    } catch (error) {
    }

    try {
      // Only USDC has total borrows and other are zero
      if (token?.borrowable) {
        const totalBorrows = await getTokenTotalBorrow(token)
        const formattedBorrow = formatUnits(totalBorrows, token?.decimals).toString()
        token.totalBorrows = formattedBorrow
      } else {
        token.totalBorrows = '0'
      }
    } catch (error) {

    }

    if (token?.borrowable) {
      try {
        if (token.totalBorrows === env.EMPTY_VALUE || token.availableLiquidity === env.EMPTY_VALUE)
          throw new Error()

        token.borrowApy = await getBorrowTokenAPY(token)
      } catch (error) {
      }
    }

    try {

      if (token.totalBorrows === env.EMPTY_VALUE || token.availableLiquidity === env.EMPTY_VALUE)
        throw new Error()

      const supplyApy = await getSupplyAPY(token)
      const formattedSupplyApy = formatEther(supplyApy)
      token.supplyApy = formattedSupplyApy

    } catch (error) {
    }

    try {
      const totalBorrows = parseEther(token.totalBorrows)
      const availableLiquidity = parseEther(token.availableLiquidity)
      token.utilizationRate = await getUtilizationRate(token, availableLiquidity, totalBorrows)
    } catch (error) {
    }

    try {
      token.collateralEnabled = await getCollateralEnabledStatus(token)
    } catch (error) {

    }

    return token
  }

  const fetchDexFields = async (token) => {
    try {
      const price = await getDexTokenPrice(token)
      token.dexPrice = formatEther(price.toString()).toString()
    } catch (error) {
    }

    if (token.market) {
      try {
        const vaults = await getTokenVaults(token)
        const ratio = vaults[2]
        token.ratio = createBigNumber(ratio).div(10).toNumber()
      } catch (error) {
      }
    }

    try {
      const totalControlledValue = await getDexTotalControlledValue(false)
      token.dexTotalControlledValue = formatEther(totalControlledValue.toString()).toString()
    } catch (error) {
    }

    try {
      const totalSupply = await getDexTotalSupply()
      token.dexTotalSupply = formatEther(totalSupply.toString()).toString()
    } catch (error) {
    }

    try {
      const bankBalance = await getDexBankBalance(token)
      token.dexBankBalance = formatEther(bankBalance.toString())
    } catch (error) {
    }

    try {
      const depositFee = await getTransferFee(token, parseEther('0.1'), false)
      token.dexDepositFee = createBigNumber(depositFee).div(100).toString()
    } catch (error) {
    }

    try {
      const withdrawFee = await getTransferFee(token, parseEther('0.1'), true)
      token.dexWithdrawFee = createBigNumber(withdrawFee).div(100).toString()
    } catch (error) {
    }
    return token
  }

  const fetchFieldsInTheBackground = async (tokens) => {
    await Promise.all(tokens.map(async (token) => {
      let updatedToken = token

      // filling client store with EMPTY_VALUE to display skeletons
      tryUpdateClientStores(updatedToken)

      // Update Token Price
      try {
        const price = await getTokenPrice(updatedToken)
        const formattedPrice = formatEther(price).toString()
        updatedToken = { ...updatedToken, price: formattedPrice }
      } catch (error) {
        // Handle error
      }

      // Dex fields can't be fetched in parallel this render issues in GMI Basket records
      const dexFields = await fetchDexFields(updatedToken)
      updatedToken = { ...updatedToken, ...dexFields }

      // Fetch Borrow and Portfolio Fields in parallel
      const [borrowFields, portfolioFields] = await Promise.all([
        fetchBorrowFields(updatedToken),
        fetchPortfolioFields(updatedToken),
      ])

      updatedToken = { ...updatedToken, ...borrowFields, ...portfolioFields }
      tryUpdateClientStores(updatedToken)
      updateQueryCache(updatedToken)
    }))
  }

  const getQuery = async () => {
    const tokens = getTokensList()
    fetchFieldsInTheBackground(tokens)
    return tokens
  }

  return useQuery({
    queryKey: [queries.GET_TOKENS_LIST],
    queryFn: getQuery,
    enabled: enabled,
  })
}

export default useGetTokensList