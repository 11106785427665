import { useMemo, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'

import env from '~/env'
import { ValidationException } from '~/consts/exceptions'

import { createBigNumber } from '~/utils/math'
import { truncateAmount } from '~/utils/ui'

import useGetHealthFactor from '~/stores/server/core/useGetHealthFactor'
import useGetTotalBorrows from '~/stores/server/borrow/useGetTotalBorrows'
import useLendWithdraw from '~/stores/server/lend/useLendWithdraw'

import Skeleton from '../Skeleton'
import { PriceInput } from '../PriceInput'
import AsyncButton from '../AsyncButton'
import useGetTokensList from '~/stores/server/core/useGetTokensList'

export default function PositionWithdrawModal({ position, onClose = () => { } }) {

  const [withdrawDetaiVisible, setWithdrawDetailVisible] = useState(true)
  const [amount, setAmount] = useState("")
  const [buttonLoading, setButtonLoading] = useState(false)

  const tokenListQuery = useGetTokensList({})
  const { mutate: withdraw } = useLendWithdraw({ tokenAddress: position.address })
  const totalBorrowsQuery = useGetTotalBorrows({})
  // const newHealthFactorQuery = useGetHealthFactor({ token: position, amount: createBigNumber(position.userPoolBalance).minus(amount || '0').toString() })
  const currentHealthFactorQuery = useGetHealthFactor({ token: position, amount: position.userPoolBalance })

  const handleMaxClicked = () => {
    setAmount(maxAllowed)
  }

  const handleWithdraw = () => {

    if (!amount)
      return new ValidationException('Fill Withdraw Amount First')

    if (createBigNumber(amount.toString()).gt(maxAllowed.toString()))
      return new ValidationException('Withdraw Amount Is Greater than Available To Withdraw Amount')

    setButtonLoading(true)

    withdraw(amount, {
      onSettled: () => {
        setButtonLoading(false)
      },
      onSuccess: () => {
        setAmount('')
        onClose()
      }
    })
  }

  const size = useMemo(() => {

    if (position.userPoolBalance === env.EMPTY_VALUE)
      return env.EMPTY_VALUE

    let size = `${truncateAmount(position.userPoolBalance)} ${position.name}`

    if (position.price !== env.EMPTY_VALUE) {
      const sizeInUSD = createBigNumber(position.price).mul(position.userPoolBalance).toString()
      size += ` (≈$${truncateAmount(sizeInUSD, 2)})`
    }

    return size

  }, [position.userPoolBalance])

  const remainingSize = useMemo(() => {

    if (position.userPoolBalance === env.EMPTY_VALUE)
      return env.EMPTY_VALUE

    let wantToWithdraw = 0

    if (amount && parseFloat(amount) !== 0)
      wantToWithdraw = amount

    const remaining = createBigNumber(position.userPoolBalance).minus(wantToWithdraw).toString()

    let returnValue = `${truncateAmount(remaining)} ${position.name}`

    if (position.price !== env.EMPTY_VALUE) {
      const remainingInUSD = createBigNumber(position.price).mul(remaining).toString()
      returnValue += ` (≈$${truncateAmount(remainingInUSD, 2)})`
    }

    return returnValue

  }, [position.userPoolBalance, amount])

  const maxWithdrawAble = useMemo(() => {

    // maxAmountCanWithdraw = getUserCollateralValue() - borrowBalance()

    if (position.userPoolBalance === env.EMPTY_VALUE || position.price === env.EMPTY_VALUE || tokenListQuery.isLoading || !tokenListQuery.data)
      return env.EMPTY_VALUE

    // converting position balance to USD
    const balanceUSDC = createBigNumber(position.userPoolBalance).mul(position.price)
    let debtInUSD = createBigNumber(0)

    // calculating debt in USD
    const borrowableTokens = (tokenListQuery.data || []).filter(x => x.borrowable && x.userBorrows !== env.EMPTY_VALUE && x.price !== env.EMPTY_VALUE && createBigNumber(x.userBorrows.toString()).gt(0))

    for (const token of borrowableTokens) {
      const tokenDebtUSD = createBigNumber(token.userBorrows).mul(token.price).toString()
      debtInUSD = debtInUSD.plus(tokenDebtUSD)
    }

    const maxWithdrawAbleInUSD = balanceUSDC.minus(debtInUSD)
    const maxWithdrawAbleInPositionCurrency = maxWithdrawAbleInUSD.dividedBy(position.price)

    return maxWithdrawAbleInPositionCurrency.toString()

  }, [position.userPoolBalance, tokenListQuery.data])

  const maxAllowed = useMemo(() => {

    const maxWithdraw = maxWithdrawAble === env.EMPTY_VALUE ? 0 : maxWithdrawAble

    return Math.min(position.userPoolBalance, maxWithdraw)
  }, [position.userPoolBalance, maxWithdrawAble])

  const buttonDisabledReason = useMemo(() => {

    if (!amount)
      return 'Fill Withdraw Amount First'

    if (createBigNumber(amount.toString()).gt(maxAllowed.toString()))
      return 'Requested Amount Is Greater Than Max Allowed'

  }, [amount, maxAllowed])

  return (
    <Modal show={true} onHide={onClose} centered className='buy_gloop_modal' dialogClassName='modal-custom-size'>
      <Modal.Body className='p-4 radius-8 bg-trans1 border-dark w-100'>
        <div className='font-18 bold-600 color-white'>Withdraw</div>
        <div>
          <hr className='hr-3 border-dark-green' />
        </div>
        <div className='radius-8 border-2 border-dark-green p-3 mt-4'>
          <div className='d-flex space-between v-center'>
            <div className='font-16 bold-700 color-white'>Details</div>
            <div className='radius-8 bg-trans px-3 py-2 font-14 bold-700 color-white cursur-pointer' onClick={() => setWithdrawDetailVisible(!withdrawDetaiVisible)}>{withdrawDetaiVisible ? 'Hide' : 'Show'}</div>
          </div>
          <div className={`d-flex v-center space-between mt-2 ${!withdrawDetaiVisible ? "d-none" : ""}`}>
            <div>
              <div className='font-16 bold-300 color-gray my-2'>
                <span className='mr-10' >Size</span>
                {/* <Info content="Lorem ipsum dolor sit amet consectetur lorem11155" /> */}
              </div>
            </div>
            <div className='text-end'>
              <Skeleton loading={size === env.EMPTY_VALUE} width='80px'>
                <div className='font-16 bold-700 color-white my-2'>
                  {size}
                </div>
              </Skeleton>
            </div>
          </div>
          <div className={`d-flex v-center space-between ${!withdrawDetaiVisible ? "d-none" : ""}`}>
            <div>
              <div className='font-16 bold-300 color-gray my-2'>
                <span className='mr-10' >Total Debt</span>
              </div>
            </div>
            <div className='text-end'>
              <Skeleton loading={totalBorrowsQuery.isLoading} width='80px'>
                <div className='font-16 bold-700 color-white my-2'>
                  ${truncateAmount(totalBorrowsQuery.data, 2)}
                </div>
              </Skeleton>
            </div>
          </div>
          <div className={`d-flex v-center space-between ${!withdrawDetaiVisible ? "d-none" : ""}`}>
            <div>
              <div className='font-16 bold-300 color-gray my-2'>
                <span className='mr-10' >Available to withdraw</span>
              </div>
            </div>
            <div className='text-end'>
              <Skeleton loading={maxWithdrawAble === env.EMPTY_VALUE} width='80px'>
                <div className='font-16 bold-700 color-white my-2'>
                  {truncateAmount(maxWithdrawAble)} {position.name}
                </div>
              </Skeleton>
            </div>
          </div>
          <div className={`d-flex v-center space-between ${!withdrawDetaiVisible ? "d-none" : ""}`}>
            <div>
              <div className='font-16 bold-300 color-gray my-2'>
                <span className='mr-10' >Current Health Factor</span>
              </div>
            </div>
            <div className='text-end'>
              <Skeleton loading={currentHealthFactorQuery.isLoading} width='80px'>
                <div className='font-16 bold-700 color-white my-2'>
                  {truncateAmount(currentHealthFactorQuery.data, 2)}%
                </div>
              </Skeleton>
            </div>
          </div>
        </div>
        <div className='font-14 bold-400 color-gray mt-4 mb-2'>Withdraw</div>
        <Skeleton loading={position.userPoolBalance === env.EMPTY_VALUE || maxWithdrawAble === env.EMPTY_VALUE}>
          <div style={{ padding: '8px 18px' }} className='radius-8 bg-trans mt-2 desktop-flex space-between v-center'>
            <div className='w-95-100 d-flex v-center my-1 mr-10'>
              {
                position
                  ? <>
                    <img src={position?.image} width={29} className='mr-10' /><PriceInput amount={amount} setAmount={setAmount} /> <span className='font-18 bold-400 color-gray mr-10'> {position?.name || ''}</span>
                  </>
                  : null
              }
            </div>
            <div className='w-5-100 d-flex v-center flex-end my-2'>

              <Button className='max-btn market font-12 bold-300 color-gray radius-4 border-gray border-1 px-1 py-1' onClick={handleMaxClicked}>
                <span>MAX</span>
              </Button>
            </div>
          </div>
        </Skeleton>
        <div className='d-flex v-center space-between mt-2'>
          <div>
            <div className='font-16 bold-300 color-gray my-2'>
              <span className='mr-10' >Remaining Size</span>
            </div>
          </div>
          <div className='text-end'>
            <Skeleton loading={remainingSize === env.EMPTY_VALUE} width='80px'>
              <div className='font-16 bold-700 color-white my-2'>
                {remainingSize}
              </div>
            </Skeleton>
          </div>
        </div>
        {/* <div className='d-flex v-center space-between mt-1'>
          <div>
            <div className='font-16 bold-300 color-gray my-2'>
              <span className='mr-10' >New Health Factor</span>
            </div>
          </div>
          <div className='text-end'>
            <Skeleton loading={newHealthFactorQuery.isLoading} width='80px'>
              <div className='font-16 bold-700 color-white my-2'>
                {truncateAmount(newHealthFactorQuery.data, 2)}%
              </div>
            </Skeleton>
          </div>
        </div> */}

        <div className='mt-2'>
          <AsyncButton onClick={handleWithdraw} loading={buttonLoading} disabledreason={buttonDisabledReason} className='mt-4'>Withdraw</AsyncButton>
        </div>
      </Modal.Body>
    </Modal >
  )
}