import { useMemo } from 'react'
import { createBigNumber } from '~/utils/math'

export const PriceInput = ({ amount, setAmount, max, onChange = () => { }, className = '' }) => {

  const formatWithCommas = (val) => {
    const parts = val.split('.')

    // Format integer part with commas
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')

    return parts.join('.')
  }

  const displayValue = useMemo(() => {

    if (!amount)
      return ''

    return formatWithCommas(amount.toString())
  }, [amount])

  const priceInputHandler = (e) => {
    let val = e.target.value.replace(/,/g, '') // Remove commas for internal handling

    onChange(val)

    if (!val) {
      setAmount('')
      return
    }

    // Allow numbers, one dot, and multiple zeros after the dot
    if (!val.match(/^\d*\.?\d*$/)) {
      return
    }

    // Prevent more than one leading zero before the decimal point
    if (val.match(/^0\d/) && !val.includes('.')) {
      return
    }

    // Check against max value if provided
    if (max !== undefined) {
      const numVal = parseFloat(val)
      if (!isNaN(numVal) && numVal > max) {
        return
      }
    }

    setAmount(val)
  }

  return (
    <input
      type="text"
      className={`${className} buy_gmi_input flex-grow-1 font-18 bold-700 color-white mr-10`}
      value={displayValue}
      onChange={priceInputHandler}
      placeholder='Enter Amount'
    />
  )
}
