import { readContract } from "@wagmi/core"

import env from "~/env"
import useUserStore from "~/stores/client/user"
import { Web3Exception } from "~/consts/exceptions"
import { config } from "~/providers/WalletContextProvider"
import gmIncentivesAbi from "~/consts/abis/GMIncentives.json"

export const getAllUserRewards = async () => {

  const { walletAddress } = useUserStore.getState()

  try {
    return await readContract(config, {
      abi: gmIncentivesAbi.abi,
      address: env.GM_INCENTIVES_CONTRACT_ADDRESS,
      functionName: "getAllUserRewards",
      args: [walletAddress],
    })

    // returns address[] memory rewardsList, uint256[] memory unclaimedAmounts, uint256[] memory pendingAmounts
  } catch (error) {
    throw new Web3Exception(`Getting All User Rewards Failed: ${error.shortMessage || 'Unknown Reason!'}`, { error })
  }
}

export const getRewardsData = async (
  rewardAddress,
) => {
  try {
    return await readContract(config, {
      abi: gmIncentivesAbi.abi,
      address: env.GM_INCENTIVES_CONTRACT_ADDRESS,
      functionName: "getRewardsData",
      args: [rewardAddress],
    })
  } catch (error) {
    throw new Web3Exception(`Getting Reward Data Failed: ${error.shortMessage || 'Unknown Reason!'}`, { rewardAddress, error })
  }
}
