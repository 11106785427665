import { useMutation, useQueryClient } from '@tanstack/react-query'
import { parseEther, parseUnits } from 'viem'


import { approve, deposit } from '~/web3/LendWeb3'
import { queries } from '~/consts/queries'

import { toastSuccess } from '~/utils/toast'

export const useLendDeposit = ({ tokenAddress, tokenDecimals = 18 }) => {

  const queryClient = useQueryClient()

  const depositToken = async (depositAmount) => {

    try {
      // const parsedAmount = parseEther(depositAmount.toString())

      const parsedAmount = parseUnits(depositAmount.toString(), tokenDecimals)

      await approve(parsedAmount, tokenAddress)
      await deposit(parsedAmount, tokenAddress)

    } catch (error) {
      // Errors are handled in web3 js codes
      throw error
    }
  }

  return useMutation({
    mutationFn: depositToken,
    onSuccess: async () => {

      // Invalidating all caches and whenever the data is used it will be fetched again
      queryClient.invalidateQueries()

      // enforcing refetch becuase unlike other queries token list might have indirect references that won't be updated by invalidating the staleTime of cache
      queryClient.refetchQueries({ queryKey: [queries.GET_TOKENS_LIST] })


      toastSuccess(`Deposit Transaction Confirmed!`)
    },
  })

}

export default useLendDeposit