import { useQuery } from '@tanstack/react-query'
import { formatEther as viemFormatEther } from 'viem'

import { queries } from '~/consts/queries'
import { getConfigurations } from '~/web3/core'

const useGetConfigurations = ({ token, enabled = true, formatEther = true }) => {

  const getData = async () => {
    const result = await getConfigurations(token)
    return formatEther ? { lendFactor: viemFormatEther(result.lendFactor), borrowFactor: viemFormatEther(result.borrowFactor) } : result
  }

  return useQuery({
    queryKey: [queries.GET_CONFIGURATIONS, token, formatEther],
    queryFn: getData,
    enabled: enabled
  })
}

export default useGetConfigurations