import { useQuery } from '@tanstack/react-query'
import { formatEther, formatUnits } from 'viem'

import { queries } from '~/consts/queries'

import { getPoolAssetBalance } from '~/web3/core'

const useGetWithdrawTokenBalance = ({ returnEtherBalance = true, token }) => {

  const getData = async () => {
    const balance = await getPoolAssetBalance(token)
    const formattedBalance = formatUnits(balance, token.decimals).toString()
    return returnEtherBalance ? formattedBalance : balance
  }

  return useQuery({
    queryKey: [queries.GET_LEND_WITHDRAW_TOKEN_BALANCE, returnEtherBalance, token],
    queryFn: getData
  })
}

export default useGetWithdrawTokenBalance