import Skeleton from '../Skeleton'
import env from '~/env'
import { truncateAmount } from '~/utils/ui'
import useGetHealthFactor from '~/stores/server/core/useGetHealthFactor'
import useGetTotalBorrows from '~/stores/server/borrow/useGetTotalBorrows'
import useGetTotalCollateral from '~/stores/server/borrow/useGetTotalCollateral'

export default function PortfolioOverall() {

  const healthFactorQuery = useGetHealthFactor({ token: { address: env.USDC_TOKEN_ADDRESS, name: 'USDC' }, amount: '0' })
  const totalBorrowsQuery = useGetTotalBorrows({})
  const totalCollateralQuery = useGetTotalCollateral({})

  return (
    <div className="my-4">
      <div className='overall-usdc-card bg-trans' style={{ padding: '12px' }}>

        <div className='d-flex' style={{ flexWrap: 'wrap' }}>
          <div className='position-information-card radius-8 p-3 m-2' style={{ width: '31%' }}>
            <div className='d-flex space-between v-center'>
              <div className='p-2'>
                <div className='font-16 bold-600 color-white'>Total Collat. Value</div>
              </div>
            </div>
            <Skeleton loading={totalCollateralQuery.isLoading}>
              <span className='font-16 color-white px-2 pb-2' title={`$${totalCollateralQuery?.data}`}>${truncateAmount(totalCollateralQuery.data, 2)}</span>
            </Skeleton>
          </div>
          <div className='position-information-card radius-8 p-3 m-2' style={{ width: '31%' }}>
            <div className='d-flex space-between v-center'>
              <div className='p-2'>
                <div className='font-16 bold-600 color-white'>Total Borrows</div>
              </div>
            </div>
            <Skeleton loading={totalBorrowsQuery.isLoading}>
              <span className='font-16 color-white px-2 pb-2'>${truncateAmount(totalBorrowsQuery?.data, 2)}</span>
            </Skeleton>
          </div>
          <div className='position-information-card radius-8 p-3 m-2' style={{ width: '31%' }}>
            <div className='d-flex space-between v-center'>
              <div className='p-2'>
                <div className='font-16 bold-600 color-white'>Health Factor</div>
              </div>
            </div>
            <Skeleton loading={healthFactorQuery.isLoading}>
              <span className='font-16 color-white px-2 pb-2'>{truncateAmount(healthFactorQuery?.data, 2)}</span>
            </Skeleton>
          </div>
        </div>
      </div>
    </div >
  )
}