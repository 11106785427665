import { useQuery } from '@tanstack/react-query'

import { queries } from '~/consts/queries'
import env from '~/env'
import { getAllUserRewards } from '~/web3/GMIncentivesWeb3'

const useGetAllUserRewards = ({ account, enabled = true }) => {
  const getData = async () => {
    const allUserRewards = await getAllUserRewards(account)

    return allUserRewards || env.EMPTY_VALUE
  }

  return useQuery({
    queryKey: [queries.GET_ALL_USER_REWARDS, account],
    queryFn: getData,
    enabled: enabled
  })
}

export default useGetAllUserRewards