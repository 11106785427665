import { useMemo, useState } from 'react'
import { Form } from 'react-bootstrap'
import CollateralModal from './CollateralModal'
import { enableCollateral } from '~/web3/borrowWeb3'
import useChangeCollateralEnableStatus from '~/stores/server/borrow/useChangeCollateralEnableStatus'
import { createBigNumber } from '~/utils/math'


export default function CollateralSwitch({ token, label = '', className = '' }) {

  const [isChecked, setIsChecked] = useState(token.collateralEnabled)
  const [collateralModalVisible, setCollateralModalVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const { mutate: disable } = useChangeCollateralEnableStatus({ token, enable: false })
  const { mutate: enable } = useChangeCollateralEnableStatus({ token, enable: true })

  const handleChangeStatus = (checked) => {

    setIsChecked(prev => !prev)
    setIsLoading(true)

    const operation = checked ? enable : disable

    operation({}, {
      onSuccess: () => {
        setIsChecked(checked)
      },
      onSettled: () => {
        setIsLoading(false)
      },
      onError: () => {
        setIsChecked(!checked)
      },
    })
  }

  const handleCheckboxChanged = () => {

    if (isChecked)
      setCollateralModalVisible(true)
    else
      handleChangeStatus(true)
  }

  const isDiabled = useMemo(() => {
    return !token.collateral || isLoading || isChecked && createBigNumber(token.userPoolBalance).lte(0)
  }, [isLoading, token, isChecked])

  return (
    <>
      <Form>
        <Form.Check
          type="switch"
          id="collateral-switch"
          label={label}
          className={`${className} collateral-switch`}
          disabled={isDiabled}
          checked={isChecked}
          onChange={handleCheckboxChanged}
        />
      </Form>
      {
        collateralModalVisible
          ? <CollateralModal token={token} onSuccess={() => handleChangeStatus(false)} onClose={() => setCollateralModalVisible(false)} />
          : null
      }
    </>

  )
}