export const routes = {
  Home: { name: 'Home', path: '/', locked: false, authenticated: false },
  Dashboard: { name: 'Dashboard', path: '/dashboard', locked: true, authenticated: false },
  Gmi: { name: 'GMI', path: '/gmi', locked: false, authenticated: false },
  Leaderboard: { name: 'Leaderboard', path: '/leaderboard', locked: true, authenticated: false },
  Loop: { name: 'Loop', path: '/loop', locked: true, authenticated: false },
  Mint: { name: 'Mint', path: '/mint', locked: true, authenticated: false },
  Liquidate: { name: 'Liquidate', path: '/liquidate', locked: true, authenticated: false },
  Borrow: { name: 'Deposit/Borrow', path: '/borrow', locked: true, authenticated: false },
  Lend: { name: 'Lend USDC', path: '/lend', locked: true, authenticated: false },
}