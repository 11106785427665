import MarketOverview from './MarketOverview'
import MarketBorrow from './MarketBorrow'
import useBorrowStore from '~/stores/client/borrow'


export default function Markets() {
  const selectedMarket = useBorrowStore((state) => state.selectedMarket)

  return (
    <>
      <div className="px-sm-4 pt-4 pb-4 bg-trans radius-8">
        {
          selectedMarket ? (
            <MarketBorrow />
          ) : (
            <MarketOverview />
          )
        }
      </div>
    </>
  )
}