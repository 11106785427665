import { formatEther, parseEther } from 'viem'
import useGetTokenName from '~/stores/server/core/useGetTokenName'
import useGetAPR from '~/stores/server/lend/useGetAPR'
import { truncateString } from '~/utils/truncateString'
import { truncateAmount } from '~/utils/ui'
import Skeleton from '../Skeleton'

import ARBIcon from '~/assets/img/tokens/arbitrum.svg'
import EmptyToken from '~/assets/img/tokens/empty-token.svg'

const RewardTokensTableRow = ({ tokenAddress, totalUnclaimedReward }) => {

  const tokenNameQuery = useGetTokenName({ tokenAddress })

  const ImageMapper = {
    "FAKE ARB": ARBIcon,
    "POOLG": EmptyToken
  }
  // APR
  const { data: APR, isLoading: isLoadingAPR } = useGetAPR({
    rewardTokenAddress: tokenAddress,
  })

  return (
    <tr key={tokenAddress}>
      <Skeleton loading={tokenNameQuery.isLoading}>
        <div className='d-flex' style={{ gap: "8px" }}>
          <img id="tokenImage" src={ImageMapper[tokenNameQuery.data?.symbol]} width={22} height={22} />
          <td title={truncateString(tokenAddress)} className="color-gray font-14 bold-300">
            <a className='color-gray' href={`https://arbiscan.io/address/${tokenAddress}`} target='_blank'>{`${tokenNameQuery.data?.name} (${tokenNameQuery.data?.symbol})`}</a>
          </td>
        </div>
      </Skeleton>
      <td className="color-gray font-14 bold-300">
        {truncateAmount(formatEther(totalUnclaimedReward.toString()).toString())}
      </td>
      <td className="color-gray font-14 bold-300">
        {/* // TODO This should be unclaimedRewards * tokenPrice, but we don't have tokenPrice yet. Change this afterwards */}
        {/* {truncateAmount(formatEther(totalUnclaimedRewards[index]?.toString()).toString())} (* TBD) */}
        TBD
      </td>
      <Skeleton loading={isLoadingAPR}>
        <td className="color-gray font-14 bold-300">
          {truncateAmount(APR, 2)}%
        </td>
      </Skeleton>

    </tr>
  )
}

export default RewardTokensTableRow

