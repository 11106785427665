import React, { useState, useEffect, useMemo } from 'react'
import { Row, Col, Button, Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import SidebarSocial from '../components/SidebarSoical'

import leaderboard_img1_url from "../assets/img/leaderboard_img1.svg"
import gmi_img2_url from "../assets/img/gmi_img2.svg"
import leaderboard_img2_url from "../assets/img/leaderboard_img2.svg"
import leaderboard_img3_url from "../assets/img/leaderboard_img3.svg"
import dashboard_img2_url from "../assets/img/dashboard_img2.svg"
import leaderboard_img4_url from "../assets/img/leaderboard_img4.png"

import LeaderboardTable from '../components/leaderboard/LeaderboardTable'
import { routes } from '../consts/routes'
import useGetLeaderboard from '~/stores/server/leaderboard/useGetLeaderboard'
import useGetUserDetails from '~/stores/server/leaderboard/useGetUserDetails'
import Skeleton from '~/components/Skeleton'
import { copyToClipboard, truncateAmount } from '~/utils/ui'
import AsyncButton from '~/components/AsyncButton'
import eventEmitter from '~/utils/emitter'
import { events } from '~/consts/events'
import AddReferral from '~/components/wallet/AddReferral'
import useUserStore from '~/stores/client/user'
import { useAccount } from 'wagmi'

export default function Leaderboard() {

    const userDetailsQuery = useGetUserDetails()
    const leaderboardQuery = useGetLeaderboard()

    const { isConnected } = useAccount()
    const isAuthenticated = useUserStore((state) => state.isAuthenticated)
    const hasReferrer = useUserStore((state) => state.hasReferrer)

    const [addReferralModalVisible, setAddReferralModalVisible] = useState(false)

    const handleOpenReferral = () => {
        setAddReferralModalVisible(true)
    }

    const addReferralButtonDisabledReason = useMemo(() => {

        if (!isAuthenticated() || !isConnected)
            return 'Please Connect Wallet First!'

        if (hasReferrer)
            return 'Referral Is Already Used!'

    }, [isConnected, isAuthenticated, hasReferrer])

    return (
        <div>
            <SidebarSocial />
            <section>
                <Container className='mt-5 mb-3'>
                    <Row className='radius-8 bg-trans m-0 py-3 px-3 min-h-150'>
                        <Col sm={9} className='w-75-100'>
                            <div className='desktop-flex v-center'>
                                <div className='my-3 mr-20'>
                                    <img src={leaderboard_img1_url} width={62} />
                                </div>
                                <div className='color-white font-16 my-2'>
                                    Earn points for participating in Gloop's growing ecosystem.<br />
                                    Refer friends, lend, and borrow USDC to earn...
                                </div>
                            </div>
                        </Col>
                        <Col sm={3} className='w-25-100'>
                            <div className='mobile-left my-3 desktop-show'>
                                <a href="#" target='_blank' className='gloop-btn-second btn font-16 bold-400 radius-8 bg-trans-0 border-white color-white p-10-25 my-2 min-w-200'><span className='mr-10'>How to earn Points</span><img src={gmi_img2_url} width={12} /></a>
                            </div>
                            <div className='mobile-left mt-2 mobile-show'>
                                <a href="#" target='_blank' className='gloop-btn-second btn font-16 bold-400 radius-8 bg-trans-0 border-white color-white p-10-25 my-2 min-w-200'><span className='mr-10'>How to earn Points</span><img src={gmi_img2_url} width={12} /></a>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section>
                <Container className='mt-3 mb-2'>
                    <Row className=''>
                        <Col sm={12}>
                            <div className='space-between align-items-center my-2 desktop-flex'>
                                <div className='mt-4 my-2 mobile-show'>
                                    <AsyncButton className='gloop-btn-primary font-16 bold-700 radius-8 bg-green border-green color-dark p-10 min-w-200' disabledreason={addReferralButtonDisabledReason} onClick={handleOpenReferral}>Add Referral</AsyncButton>
                                </div>
                                <div className='font-32 bold-700 color-white d-flex align-items-center my-2'>
                                    My Dashboard
                                </div>

                                <div className='my-2 desktop-show'>
                                    <AsyncButton className='gloop-btn-primary font-16 bold-700 radius-8 bg-green border-green color-dark p-10 min-w-200' disabledreason={addReferralButtonDisabledReason} onClick={handleOpenReferral}>Add Referral</AsyncButton>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className='overflow-hidden'>
                        <Col sm={12} className='' >
                            <Skeleton loading={userDetailsQuery.isLoading} height='200px'>

                                <div className='mobile-show'>
                                    <div className="radius-8 bg-trans px-4 pt-4 pb-4 d-flex flex-column" style={{ gap: '16px' }}>
                                        <div className='d-flex v-center space-between'>
                                            <div className='font-16 bold-300 color-gray'>
                                                <span className='mr-10' >Lending Points</span>
                                            </div>
                                            <Skeleton loading={userDetailsQuery.isLoading || !userDetailsQuery.data?.lendingUSDCPoints} width='80px'>
                                                <div className='font-16 bold-700 color-white text-end'>
                                                    {truncateAmount(userDetailsQuery.data?.lendingUSDCPoints || '', 2)}
                                                </div>
                                            </Skeleton>
                                        </div>
                                        <div className='d-flex v-center space-between'>
                                            <div className='font-16 bold-300 color-gray'>
                                                <span className='mr-10' >Borrowing Points</span>
                                            </div>
                                            <Skeleton loading={userDetailsQuery.isLoading || !userDetailsQuery.data?.borrowingUSDCPoints} width='80px'>
                                                <div className='font-16 bold-700 color-white text-end'>
                                                    {truncateAmount(userDetailsQuery.data?.borrowingUSDCPoints || '', 2)}
                                                </div>
                                            </Skeleton>
                                        </div>
                                        <div className='d-flex v-center space-between'>
                                            <div className='font-16 bold-300 color-gray'>
                                                <span className='mr-10' >Referral Boost</span>
                                            </div>
                                            <Skeleton loading={userDetailsQuery.isLoading || !userDetailsQuery.data?.referralBoost} width='80px'>
                                                <div className='font-16 bold-700 color-white my-3 text-end'>
                                                    {truncateAmount(userDetailsQuery.data?.referralBoost || '', 2)}
                                                </div>
                                            </Skeleton>
                                        </div>
                                        <div className='d-flex v-center space-between'>
                                            <div className='font-16 bold-300 color-gray'>
                                                <span className='mr-10' >Users Referred</span>
                                            </div>
                                            <Skeleton loading={userDetailsQuery.isLoading || isNaN(userDetailsQuery.data?.usersReferred)} width='80px'>
                                                <div className='font-16 bold-700 color-white text-end'>
                                                    {truncateAmount(userDetailsQuery.data?.usersReferred, 2)}
                                                </div>
                                            </Skeleton>
                                        </div>
                                        <div className='d-flex v-center space-between'>
                                            <div className='font-16 bold-300 color-gray'>
                                                <span className='mr-10' >Total Points</span>
                                            </div>
                                            <Skeleton loading={userDetailsQuery.isLoading || !userDetailsQuery.data?.totalEarnedPoints} width='80px'>
                                                <div className='font-16 bold-700 color-green text-end'>
                                                    {truncateAmount(userDetailsQuery.data?.totalEarnedPoints || '', 2)}
                                                </div>
                                            </Skeleton>
                                        </div>
                                        <div className='d-flex v-center space-between'>
                                            <div className='font-16 bold-300 color-gray'>
                                                <span className='mr-10' >Rank</span>
                                            </div>
                                            <Skeleton loading={userDetailsQuery.isLoading || !userDetailsQuery.data?.rank} width='80px'>
                                                <div className='font-16 bold-700 color-green text-end'>
                                                    #{userDetailsQuery.data?.rank}
                                                </div>
                                            </Skeleton>
                                        </div>
                                    </div>
                                </div>

                                <div className='desktop-show bg-trans1 radius-8 p-3 my-2'>
                                    <div className="d-flex space-between" style={{ gap: '16px' }}>
                                        <div className='d-flex flex-column space-between flex-grow-1'>
                                            <div className='p-2'>
                                                <span className='font-14 bold-300 color-gray'>Lending Points</span>
                                            </div>
                                            <Skeleton loading={userDetailsQuery.isLoading || !userDetailsQuery.data?.lendingUSDCPoints}>
                                                <div className='font-20 bold-700 color-white px-2 pb-2'>{truncateAmount(userDetailsQuery.data?.lendingUSDCPoints || '', 2)}</div>
                                            </Skeleton>
                                        </div>
                                        <div className='d-flex flex-column space-between flex-grow-1'>
                                            <div className='p-2'>
                                                <span className='font-14 bold-300 color-gray'>Borrwing Points</span>
                                            </div>
                                            <Skeleton loading={userDetailsQuery.isLoading || !userDetailsQuery.data?.borrowingUSDCPoints}>
                                                <div className='font-20 bold-700 color-white px-2 pb-2'>{truncateAmount(userDetailsQuery.data?.borrowingUSDCPoints || '', 2)}</div>
                                            </Skeleton>
                                        </div>
                                        <div className='d-flex flex-column space-between flex-grow-1'>
                                            <div className='p-2'>
                                                <span className='font-14 bold-300 color-gray'>Referral Boost</span>
                                            </div>
                                            <Skeleton loading={userDetailsQuery.isLoading || !userDetailsQuery.data?.referralBoost}>
                                                <div className='font-20 bold-700 color-white px-2 pb-2'>{truncateAmount(userDetailsQuery.data?.referralBoost || '', 2)}</div>
                                            </Skeleton>
                                        </div>
                                        <div className='d-flex flex-column space-between flex-grow-1'>
                                            <div className='p-2'>
                                                <span className='font-14 bold-300 color-gray'>Users Referred</span>
                                            </div>
                                            <Skeleton loading={userDetailsQuery.isLoading}>
                                                <div className='font-20 bold-700 color-white px-2 pb-2'>{truncateAmount(userDetailsQuery.data?.usersReferred, 2)}</div>
                                            </Skeleton>
                                        </div>
                                        <div className='d-flex flex-column space-between flex-grow-1'>
                                            <div className='p-2'>
                                                <span className='font-14 bold-300 color-gray'>Total Points</span>
                                            </div>
                                            <Skeleton loading={userDetailsQuery.isLoading || !userDetailsQuery.data?.totalEarnedPoints}>
                                                <div className='font-20 bold-700 color-green px-2 pb-2'>{truncateAmount(userDetailsQuery.data?.totalEarnedPoints || '', 2)}</div>
                                            </Skeleton>
                                        </div>
                                        <div className='d-flex flex-column space-between flex-grow-1'>
                                            <div className='p-2'>
                                                <span className='font-14 bold-300 color-gray'>Rank</span>
                                            </div>
                                            <Skeleton loading={userDetailsQuery.isLoading || !userDetailsQuery.data?.rank}>
                                                <div className='font-20 bold-700 color-green px-2 pb-2'>#{userDetailsQuery.data?.rank}</div>
                                            </Skeleton>
                                        </div>
                                    </div>
                                </div>
                            </Skeleton>

                        </Col>
                    </Row>
                </Container>
                <Container className='mt-3 mb-2'>
                    <Row className=''>
                        <Col sm={12}>
                            <div className='space-between align-items-center my-2 desktop-flex '>
                                <div className='font-32 bold-700 color-white d-flex align-items-center my-2'>
                                    Leaderboard
                                </div>
                            </div>

                        </Col>
                    </Row>
                    <Row className='overflow-hidden'>
                        <Col sm={6} className='' >
                            <Skeleton loading={leaderboardQuery.isLoading || !leaderboardQuery.data?.totalUsers} height='100px'>
                                <div className='bg-trans1 radius-8 p-3 my-2'>
                                    <div className='d-flex space-between v-center'>
                                        <div className='p-2'>
                                            <img src={leaderboard_img3_url} width={14} className='mr-10' />
                                            <span className='font-14 bold-300 color-gray'>Total Users</span>
                                        </div>
                                    </div>
                                    <div className='font-20 bold-700 color-white px-2 pb-2'>{truncateAmount(leaderboardQuery?.data?.totalUsers, 2)}</div>
                                </div>
                            </Skeleton>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section>
                <Container>
                    <Row className='mt-3'>
                        <Col sm={12}>
                            <LeaderboardTable rankings={leaderboardQuery?.data?.LeaderboardList || []} isPlaceholderData={leaderboardQuery.isPlaceholderData} isLoading={leaderboardQuery.isLoading} />
                        </Col>
                    </Row>
                </Container>
            </section>
            <section>
                <div className='ptb-50'></div>
            </section>
            {
                addReferralModalVisible
                    ? <AddReferral onClose={() => setAddReferralModalVisible(false)} />
                    : null
            }
        </div >
    )
}

