import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useAccount } from 'wagmi'

import { toastSuccess } from '~/utils/toast'
import { liquidate } from '~/web3/liquidation'
import { parseEther } from 'viem'
import { queries } from '~/consts/queries'

export const usePositionLiquidate = () => {

  const { address } = useAccount()
  const queryClient = useQueryClient()

  const liquidatePosition = async ({ borrowedAssetAddress, repayAmount, borrowerAddress }) => {

    try {
      const parsedAmount = parseEther(repayAmount.toString())
      await liquidate(borrowedAssetAddress, parsedAmount, borrowerAddress, address)
    } catch (error) {
      // Errors are handled in web3 js codes
      throw error
    }
  }

  return useMutation({
    mutationFn: liquidatePosition,
    onSuccess: () => {

      // Invalidating all caches and whenever the data is used it will be fetched again
      queryClient.invalidateQueries()

      // enforcing refetch becuase unlike other queries token list might have indirect references that won't be updated by invalidating the staleTime of cache
      queryClient.refetchQueries({ queryKey: [queries.GET_TOKENS_LIST] })


      toastSuccess(`Liquidation Success!`)
    },
  })

}

export default usePositionLiquidate