import { useQuery } from '@tanstack/react-query'
import { parseEther, formatEther as viemFormatEther } from 'viem'

import { queries } from '~/consts/queries'
import { calculateHealthFactor } from '~/web3/core'
import { useDebounce } from '~/hooks/useDebounce'

const useGetHealthFactor = ({ token, amount, account, enabled = true, formatEther = true }) => {

  const debouncedAmount = useDebounce(amount)

  const getData = async () => {
    const parsedAmount = parseEther(debouncedAmount.toString())
    const healthFactor = await calculateHealthFactor(token, parsedAmount, account)
    return formatEther ? viemFormatEther(healthFactor) : healthFactor
  }

  return useQuery({
    queryKey: [queries.GET_HEALTH_FACTOR, token, account, debouncedAmount, formatEther],
    queryFn: getData,
    enabled: enabled
  })
}

export default useGetHealthFactor