import { useState } from 'react'
import { Modal, Button } from 'react-bootstrap'

export default function CollateralModal({ token, onSuccess = () => { }, onClose = () => { } }) {

  const [detailVisible, setDetailVisible] = useState(false)

  return (
    <Modal show={true} onHide={onClose} centered>
      <Modal.Body className='p-4 radius-8 bg-trans1 border-dark d-flex flex-column gap-2'>
        <div className='font-18 bold-600 color-white'>Do not use as collateral</div>
        <div>
          <hr className='hr-3 border-dark-green' />
        </div>
        <div className='radius-8 border-2 border-dark-green p-3'>
          <div className='d-flex space-between v-center'>
            <div className='font-16 bold-700 color-white'>Details</div>
            <div className='radius-8 bg-trans px-3 py-2 font-14 bold-700 color-white cursur-pointer' onClick={() => setDetailVisible(prev => !prev)}>{!detailVisible ? 'Show' : 'Hide'}</div>
          </div>
          <div className={`d-flex v-center space-between mt-2 ${!detailVisible ? "d-none" : ""}`}>
            <div>
              <div className='font-16 bold-300 color-gray my-2'>
                <span className='mr-10' >Asset</span>
              </div>
            </div>
            <div className='text-end'>
              <div className='font-16 bold-700 color-white my-2'>
                {token.name}
              </div>
            </div>
          </div>
        </div>
        <div className='flex-grow-1 p-1'>
          <Button className='gloop-btn-primary font-16 bold-700 radius-8 bg-green border-green color-dark p-10-25 my-2 w-100' onClick={onSuccess}>Confirm</Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}