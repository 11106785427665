import { useEffect, useMemo, useState } from 'react'
import { Button } from 'react-bootstrap'
import gloop_img9_url from "../../assets/img/gmi_img9.svg"
import link_redirect from '../../assets/img/link-redirect.svg'
import dropdown_img from '../../assets/img/dropdown.svg'
import usdc_image from '../../assets/img/gloop_usdc.svg'
import gloop_image5 from '../../assets/img/gloop_img5.svg'

import Skeleton from '../Skeleton'
import { createBigNumber } from '~/utils/math'
import { truncateAmount } from '~/utils/ui'
import { ValidationException, Web3Exception } from '~/consts/exceptions'
import useGetWithdrawTokenBalance from '~/stores/server/lend/useGetWithdrawTokenBalance'
import useLendWithdraw from '~/stores/server/lend/useLendWithdraw'
import LendTokenDropdown from './LendTokenDropdown'
import useLendStore from '~/stores/client/lend'
import env from '~/env'
import { Tooltip } from '~/components/Tooltip'
import AsyncButton from '~/components/AsyncButton'
import { PriceInput } from '~/components/PriceInput'
import useGetTokensList from '~/stores/server/core/useGetTokensList'

export default function LendWithdraw() {

  const selectedToken = useLendStore((state) => state.selectedToken)
  const decimals = selectedToken?.decimals

  const balanceQuery = useGetWithdrawTokenBalance({ token: selectedToken })
  const { mutate: withdraw } = useLendWithdraw({ tokenAddress: selectedToken?.address, tokenDecimals: decimals })

  const [informationVisible, setInformationVisible] = useState(false)
  const [amount, setAmount] = useState("")
  const [buttonLoading, setButtonLoading] = useState(false)

  const handleMaxClicked = () => {
    setAmount(balanceQuery?.data?.toString())
  }

  const handleWithdraw = () => {

    if (!balanceQuery.data)
      return new Web3Exception('Failed To Fetch Balance', { balanceQuery }, { sendToast: true })

    if (!amount)
      return new ValidationException('Fill Withdraw Amount First')

    if (createBigNumber(amount).gt(maxWithdrawable.toString()))
      return new ValidationException('Withdraw Amount Is Greater Than Max Withdrawable Amount')

    setButtonLoading(true)

    withdraw(amount, {
      onSettled: () => {
        setButtonLoading(false)
      },
      onSuccess: () => {
        setAmount('')
      }
    })
  }

  const maxWithdrawable = useMemo(() => {
    return Math.min(balanceQuery?.data)
  }, [balanceQuery])

  const buttonDisabledReason = useMemo(() => {

    if (!balanceQuery.data)
      return 'Failed To Fetch Balance'

    if (!amount)
      return 'Fill Withdraw Amount First'

    if (createBigNumber(amount.toString()).gt(maxWithdrawable.toString()))
      return 'Withdraw Amount Is Greater Than Max Withdrawable Amount'

  }, [amount, balanceQuery, maxWithdrawable])

  // Calculate New Supplied Value
  const newDepositedValue = useMemo(() => {

    if (!selectedToken || selectedToken.price === env.EMPTY_VALUE || selectedToken.userPoolBalance === env.EMPTY_VALUE)
      return env.EMPTY_VALUE

    return Number(selectedToken.userPoolBalance) - Number(amount) * Number(selectedToken.price)
  }, [selectedToken, amount])

  return (
    <div className='marklendet-supply'>
      <div className='mt-4'>
        <div className='loop-input-title mb-2'>
          Asset
        </div>
        <LendTokenDropdown />
      </div>
      <div className='mt-4'>
        <div className='loop-input-title'>
          <span>Withdraw</span>
          <Skeleton loading={balanceQuery?.isLoading || balanceQuery.isFetching} height='20px' width='100px'>
            <span>{`Balance: ${truncateAmount(balanceQuery?.data?.toString())} ${selectedToken?.name}`}</span>
          </Skeleton>
        </div>
        <Skeleton loading={balanceQuery?.isLoading || balanceQuery?.isFetching}>
          <div style={{ padding: '8px 18px' }} className='radius-8 bg-trans mt-2 desktop-flex space-between v-center'>
            <div className='w-95-100 d-flex v-center my-1 mr-10'>
              {
                selectedToken
                  ? <>
                    <img src={selectedToken?.image} width={29} className='mr-10' /><PriceInput amount={amount} setAmount={setAmount} /> <span className='font-18 bold-400 color-gray mr-10'> {selectedToken?.name || ''}</span>
                  </>
                  : null
              }
            </div>
            <div className='w-5-100 d-flex v-center flex-end my-2'>

              <Button className='max-btn market font-12 bold-300 color-gray radius-4 border-gray border-1 px-1 py-1' onClick={handleMaxClicked}>
                <span>MAX</span>
              </Button>
            </div>
          </div>
        </Skeleton>
      </div>
      <div className="mt-4 border-line"></div>
      <div className="information-card mt-4">
        <div className="toggler-bar" onClick={() => setInformationVisible(!informationVisible)}>
          <div>Withdraw Details</div>
          <div className='d-flex align-items-center' style={{ gap: '10px' }}>
            <img src={dropdown_img} width={13} />
          </div>
        </div>
        {informationVisible && (
          <div className='mt-4'>
            <div className='information-detail-items'>
              {/* <Skeleton loading={LendAPYQuery.isLoading}>
                <div className='d-flex justify-content-between'>
                  <span className='detail-title'>Deposit APY</span>

                  <Tooltip placement='bottom-end' tooltipitem={
                    <div className='tooltip-body d-flex flex-column gap-2'>
                      <div className='d-flex justify-content-between'>
                        <span className='detail-title'>Base APY</span>
                        <span className='detail-value'>0%</span>
                      </div>
                      <div className='d-flex justify-content-between'>
                        <span className='detail-title'>Bonus APR</span>
                        <span className='detail-value'>0%</span>
                      </div>

                      <span style={{ color: '#fff', fontSize: '14px' }}>The Bonus APR will be distributed as ARB tokens. <a href={env.DOCS_URL}>Learn more.</a></span>
                    </div>

                  }>
                    <span className='detail-value primary-tooltip'>{LendAPYQuery?.data}%</span>
                  </Tooltip>

                </div>
              </Skeleton> */}

              <Skeleton loading={newDepositedValue === env.EMPTY_VALUE}>
                <div className='d-flex justify-content-between'>
                  <span className='detail-title'>New Deposited Value</span>
                  <span className='detail-value'>{truncateAmount(newDepositedValue)} </span>
                </div>
              </Skeleton>
            </div>
          </div>
        )}
      </div>
      <AsyncButton onClick={handleWithdraw} loading={buttonLoading} disabledreason={buttonDisabledReason} className='mt-4'>Withdraw</AsyncButton>
    </div >
  )
}