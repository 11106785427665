import { useQuery } from '@tanstack/react-query'
import { formatEther as viemFormatEther } from 'viem'

import { queries } from '~/consts/queries'
import { getMaxBorrowableValue } from '~/web3/borrowWeb3'

const useGetMaxBorrowableValue = ({ enabled = true, formatEther = true }) => {

  const getData = async () => {
    const max = await getMaxBorrowableValue()
    return formatEther ? viemFormatEther(max) : max
  }

  return useQuery({
    queryKey: [queries.GET_MAX_BORROWABLE_VALUE, formatEther],
    queryFn: getData,
    enabled: enabled
  })
}

export default useGetMaxBorrowableValue