import { useMutation, useQueryClient } from '@tanstack/react-query'
import { parseEther } from 'viem'

import { queries } from '~/consts/queries'

import { toastSuccess } from '~/utils/toast'
import { dexApprove, dexWithdraw } from '~/web3/GMIWeb3'
import useUserStore from '~/stores/client/user'
import env from '~/env'


export const useDexWithdraw = ({ token }) => {

  const queryClient = useQueryClient()
  const walletAddress = useUserStore(state => state.walletAddress)

  const dexWithdrawToken = async ({ amount }) => {

    try {
      const parsedAmount = parseEther(amount.toString())
      await dexApprove({ name: 'GMI', address: env.GMI_TOKEN_ADDRESS }, parsedAmount)
      await dexWithdraw(token, parsedAmount, walletAddress)

    } catch (error) {
      // Errors are handled in web3 js codes
      throw error
    }
  }

  return useMutation({
    mutationFn: dexWithdrawToken,
    onSuccess: async () => {

      // Invalidating all caches and whenever the data is used it will be fetched again
      queryClient.invalidateQueries()

      // enforcing refetch becuase unlike other queries token list might have indirect references that won't be updated by invalidating the staleTime of cache
      queryClient.refetchQueries({ queryKey: [queries.GET_TOKENS_LIST] })


      toastSuccess(`Withdraw Transaction Confirmed!`)
    },
  })

}

export default useDexWithdraw