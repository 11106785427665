const GMX_EXCHANGE_ROUTER_ADDRESS = "0x69C527fC77291722b52649E45c838e41be8Bf5d5"
const GMX_DEPOSIT_VAULT_ADDRESS = "0xF89e77e8Dc11691C9e8757e84aaFbCD8A67d7A55"
const GMX_DATA_STORE = "0xFD70de6b91282D8017aA4E741e9Ae325CAb992d8"
const ZAP_ADDRESS = "0x79079A231AAC05d96e60656146bB6bfe4280F51f"
const GMX_SYNTHETICS_ROUTER_ADDRESS = "0x7452c558d45f8afc8c83dae62c3f8a5be19c71f6"

const WITHDRAWAL_VAULT_ADDRESS = "0x0628D46b5D145f183AdB6Ef1f2c97eD1C4701C55"

export {
  GMX_DATA_STORE,
  GMX_DEPOSIT_VAULT_ADDRESS,
  GMX_EXCHANGE_ROUTER_ADDRESS,
  GMX_SYNTHETICS_ROUTER_ADDRESS,
  ZAP_ADDRESS,
}